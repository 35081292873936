<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                {{$t('Información del Pago')}}
            </template>
            <template v-slot:subtitle>
                {{$t('solicitada')}}
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-col-12 p-md-12" v-if="!mostrar">
						<DataTable :value="usuarios" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
							<template #header>
								<div class="table-header">
									<h5>{{$t('Clientes')}}</h5>
									<span class="p-input-icon-right">
										<i class="pi pi-search"/>
										<InputText v-model="filters['global']" :placeholder="$t('Buscar')+'...'" />
									</span>
								</div>
							</template>
							<Column field="id" :header="$t('ID')+' #'" :sortable="true" headerStyle="width: 70px">
								<template #body="slotProps">
									{{slotProps.data.id}}                              
								</template>
							</Column>
							<Column field="nombre"  :header="$t('Nombre Completo')">
								<template #body="slotProps">
									<div>{{slotProps.data.nombre}}</div>     
								</template>
							</Column>       
							<Column field="deuda"  :header="$t('Deuda')">
								<template #body="slotProps">
									<div v-if="slotProps.data.deuda<0" style="color:red;">
										{{moneda(slotProps.data.deuda)}}
									</div>
									<div v-else>
										{{moneda(slotProps.data.deuda)}}
									</div>
								</template>
							</Column>       
							<Column field="credito"  :header="$t('Crédito')">
								<template #body="slotProps">
									<div v-if="slotProps.data.credito<0" style="color:red;">
										{{moneda(slotProps.data.credito)}}
									</div>
								</template>
							</Column>       
							<Column field="vence" :header="$t('Acciones')" :sortable="true" headerStyle="width: 70px">
								<template #body="slotProps">
                                    <Button :disabled ="$store.state.loading" icon="pi pi-check-circle" class="p-button-rounded p-button-secondary" @click="Buscar(slotProps.data)" v-if="slotProps.data.deuda>0"/>
								</template>
							</Column>
						</DataTable>
                    </div>
                    <div class="p-field p-col-12 p-md-12"  v-if="mostrar && creditos.length>0">
                        <h4>{{$t('Crédito')}}</h4>
                        <small v-show="validationErrors.pago && submitted" class="p-error">{{$t('seleccine')}}</small>
						<DataTable :value="creditos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
                            <Column :header="$t('Fecha')" field="fecha" :sortable="true" headerStyle="width: 100px">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
                            <Column :header="$t('Póliza') + '#'" field="nombre" :sortable="true" headerStyle="width: 300px">	
								<template #body="{data}">
									{{data.codigo}}
								</template>
							</Column>
                            <Column :header="$t('Monto')" field="monto" :sortable="true">	
								<template #body="{data}">
									<div v-if="data.monto<0" style="color:red;">
										{{moneda(data.monto)}}
									</div>
									<div v-else>
										{{moneda(data.monto)}}
									</div>
								</template>
							</Column>
                            <Column :header="$t('Agregar a Factura')">	
								<template #body="{data}">
									<Checkbox v-model="data.select" :binary="true" @click="AgregarFactura(0,data)"/>
                                </template>
                            </Column>
						</DataTable>
					</div>
                    <div class="p-field p-col-12 p-md-12"  v-if="mostrar">
                        <h4>{{$t('Pagos Pendientes')}}</h4>
                        <small v-show="validationErrors.pago && submitted" class="p-error">{{$t('seleccine')}}</small>
						<DataTable :value="deudas" :scrollable="true" scrollHeight="500px" class="p-datatable-sm" v-if="deudas.length>0">
                            <Column :header="$t('Fecha')" field="fecha" :sortable="true" headerStyle="width: 100px">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
                            <Column :header="$t('Póliza') + '#'" field="nombre" :sortable="true" headerStyle="width: 300px">	
								<template #body="{data}">
									{{data.codigo}}
								</template>
							</Column>
                            <Column :header="$t('Monto')" field="monto" :sortable="true">	
								<template #body="{data}">
									<div v-if="data.monto<0" style="color:red;">
										{{moneda(data.monto)}}
									</div>
									<div v-else>
										{{moneda(data.monto)}}
									</div>
								</template>
							</Column>
                            <Column :header="$t('Vencimiento')" field="vencimiento" :sortable="true">	
								<template #body="{data}">
									{{data.vencimiento}}
								</template>
							</Column>
                            <Column :header="$t('Agregar a Factura')">	
								<template #body="{data}">
									<Checkbox v-model="data.select" :binary="true" @click="AgregarFactura(1,data)"/>
                                </template>
                            </Column>
						</DataTable>
					</div>
					<div class="p-col-6"></div>
					<div class="p-col-3" v-if="mostrar"><h4><strong>{{$t('Monto a Pagar')}}:</strong></h4></div>
					<div class="p-col-3"><h4><strong v-if="mostrar">
                        {{moneda(factura_monto)}}
                    </strong></h4></div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="p-grid p-nogutter p-justify-between" v-if="mostrar">
                    <Button :disabled ="$store.state.loading" :label="$t('Anterior')" @click="mostrar=false" icon="pi pi-angle-left" />
                    <Button :disabled ="$store.state.loading" :label="$t('Siguiente')" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Pagos','SPagos');
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
    data () {
        return {
			size: '40vw',
			i18n: null,
            submitted: false,
            validationErrors: {},
            deudas: [],
            creditos: [],
            factura: [],
            usuarios: [],
            apagar: [],
            factura_monto: 0,
            mostrar: false,
            selectedCustomers: null,
			filters: {},
			usuario: null,
			poliza: 0,
        }
    },
	created() {
        this.usuarios = [];
		this.i18n = useI18nPlugin();
		Consulta.Ini().then(result => {
            this.usuarios = result.consult.usuarios;
		}); 
	},
    methods: {
        Buscar(data){
			this.usuario = data.id;
            this.factura_monto = 0;
            this.i18n = useI18nPlugin();
			Consulta.Procesar('Buscar',{
				id: 	data.id,
			}).then(response => {
				if (response.result) {
					this.$toast.add({
						severity:'success', 
						summary: 'Éxito', 
						detail: 'Información Procesada', 
						life: 3000
					});
                    this.mostrar = true;
                    if(response.result.deudas){
                        this.deudas = response.result.deudas;
                    }
                    if(response.result.creditos){
                        this.creditos = response.result.creditos;
                    }
                    for (var i=0; i<this.deudas.length; i++){
                        this.deudas[i].select = false;
                    }
                    for (var j=0; j<this.creditos.length; j++){
                        this.creditos[j].select = false;
                    }
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail: 'No fue procesada la solicitud', 
						life: 3000
					});
				}
			});
        },
        AgregarFactura(caso,data){
            this.apagar = [];
            this.factura_monto = 0;
            this.poliza = 0;
			if(!data.select){
				this.apagar.push(data);
				this.factura_monto = this.factura_monto + parseFloat(data.monto);
			}
			if(caso){
                this.poliza = data.poliza;
				for (var i=0; i<this.deudas.length; i++){
					if(this.deudas[i].id!=data.id){
						this.deudas[i].select = false;
					}               
				}
			} else {
				for (var l=0; l<this.deudas.length; l++){
					if(this.deudas[l].select){
						this.apagar.push(this.deudas[l]);
						this.factura_monto = this.factura_monto + parseFloat(this.deudas[l].monto);
					}
				}
			} 	
			for (var k=0; k<this.creditos.length; k++){
				if(this.creditos[k].select && this.creditos[k].id!=data.id){
					this.apagar.push(this.creditos[k]);
					this.factura_monto = this.factura_monto + parseFloat(this.creditos[k].monto);
				}
			}
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm() ) {
                this.$emit('next-page', {formData: {poliza:this.poliza,factura:this.apagar,factura_monto: this.factura_monto,usuario: this.usuario}, pageIndex: 0});
            }
        },
        validateForm() {
            if (this.factura_monto==0)
                this.validationErrors['pago'] = true;
            else
                delete this.validationErrors['pago'];
            if (this.poliza==0)
                this.validationErrors['pago'] = true;
            else
                delete this.validationErrors['pago'];

            return !Object.keys(this.validationErrors).length;
        },
        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency: this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},

    }
}
</script>